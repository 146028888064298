.loader {
    position: absolute;
    background: rgba(0,0,0,0.75);
    height: 100vh;
    width: 100%;
    z-index: 999;
    .spinner {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}